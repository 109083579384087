import {
  Box,
  Card,
  CardContent,
  Unstable_Grid2 as Grid
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { HomeContext } from '../../../context/HomeContext'
import { getMegaGoDashboardQuery } from '../../../config/axios'


const MegaGoDashboard = () => {

  const [auth] = useContext(HomeContext)
  const [dashboard, setDashboard] = useState()
  const auxPath = "https://app.powerbi.com/view?r=eyJrIjoiNTBmNmIwYmMtMDk3OS00ODk0LTlkMDktOTdmNDgzNWIzNmZmIiwidCI6ImQwZDljYzdlLTc3MzQtNGRjYS1hODZjLThlOTg3ZDhhOTQzYSJ9"
  useEffect(() => {

    async function getMegaGoDashboard () {
      const {data} = await getMegaGoDashboardQuery('/mega-go-dashboard', auth.token)
      setDashboard(data.dashboard)
    }

    getMegaGoDashboard()
  }, [auth.token])


  return (

    <Card sx={{
      px: 2,
      mx: 2,
      my: 1
    }}>

      <CardContent>
        <Box sx={{ m: -1.5 }} >
          <Grid
            container
            spacing={3}
          >
          {
            dashboard && 
            <iframe width="100%" height='650px' className='dashboard-view' title={dashboard.name || "Mega GO"} src={dashboard.link || auxPath} ></iframe>

          }
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

export default MegaGoDashboard